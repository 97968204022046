import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import LanguageHOC from "./LanguageHOC";

import "@fortawesome/fontawesome-free/css/all.css";
library.add(fab, fas);
const Layout = ({ children }) => children;
export default LanguageHOC(Layout);
