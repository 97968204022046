import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

import { API_URL } from "../config";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: API_URL,
  }),
});

export default client;
