import React from "react";
import { Spinner } from "react-bootstrap";
export default function () {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#ddd",
        width: "100%",
        height: "100%",
        position: "fixed",
      }}>
      <Spinner animation="border" />
    </div>
  );
}
