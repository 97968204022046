import { gql } from "@apollo/client";

export const COMPONENT_ACTIVE_ITEM_FRAGMENT = gql`
  fragment ComponentActiveItemFragment on ComponentActiveItem {
    id
    type
    name
    file
    component {
      id
      name
      folder
    }
  }
`;
