import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import Initializing from "./Initializing";
import { ApolloProvider } from "@apollo/client";
import client from "./store/index";
import importAll from "import-all.macro";

// import Layout from "./components/Layout/index5";

// ReactDOM.render(<Layout />, document.getElementById("root"));

//TODO:helmet ekle ve public/index.html title vs kaldır
//master pageleri düzenle
//profili bitir/videoyu youtube ekle
//footer'a iletişim bilgilerini ekle
// TODO:içerik eklemede meta bilgisine gerek yok!
ReactDOM.render(
  <ApolloProvider client={client}>
    <Initializing />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
