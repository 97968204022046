import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { MASTER_PAGE } from "../../store/Queries/MasterPage";
import ResolveField from "../../components/Layout/ResolveField";
import Layout from "../../components/Layout";
import Loading from "components/Loading";
import Header from "components/Layout/Header";
export default function Home() {
  const { data, error, loading } = useQuery(MASTER_PAGE.getMasterPage);

  if (loading) return <Loading />;
  if (!data?.getMasterPage) return "Ön Sayfa Ayarlanmamış";

  const ThemeComponent = React.lazy(() =>
    import(`Themes/${data?.getMasterPage?.theme?.folder}/index.js`)
  );

  return (
    <Layout>
      <Header setting={null} />
      <React.Suspense fallback={Loading}>
        <ThemeComponent
          ResolveField={ResolveField}
          fields={data?.getMasterPage?.fields?.reduce((field, item) => {
            field[item.name] = item;
            return field;
          }, {})}
        />
      </React.Suspense>
    </Layout>
  );
}
