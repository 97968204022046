import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import importAll from "import-all.macro";
import ScrollToTop from "../helpers/ScrollToTop";
import Home from "./Home";
import Content from "./Content";
import DynemicContent from "components/Content/DynemicContent";

const packagesRouter = importAll.sync("../Packages/**/router.js");

// TODO:SİL
// import Index2 from "components/Layout/index2";
// import Index3 from "components/Layout/index3";

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <>
          <Route exact path="/" component={Home} />
          {/*content linklerini masterPageSlug/linkSlug şeklinde gerçekleştirebiliriz  */}
          {/* <Route path="/content/:slug" component={Content} /> */}
          <Route exact path="/:slug" component={Content} />
          <Route exact path={"/:masterPage/:slug"} component={DynemicContent} />

          {/* {Object.keys(packagesRouter).map((key) =>
            packagesRouter[key].default()
          )} */}

          {/* bu şekilde yap; <Route path="/:slug" component={Content} /> */}
          {/* <Route path="/:masterPage/:slug">
            {Object.keys(packagesRouter).map((key) =>
              packagesRouter[key].default()
            )}
          </Route> */}
          {/* {Object.keys(packagesRouter).map((key) =>
            packagesRouter[key].default()
          )} */}
        </>
      </Switch>
    </Router>
  );
}
