const URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000/"
    : "https://senirkentyukselisvakfi.com/";

const endPoint = "graphql";

export const API_URL = `${URL}${endPoint}`;
export const PUBLIC_PATH = (path) => `${URL}${path}`;
export const ROUTE_BASENAME = "/admin";
export const FILE_MANAGER_PATH = `/file-manager`;
