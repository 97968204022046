import { gql } from "@apollo/client";
import { MASTER_PAGE_FRAGMENT } from "../Fragment/MasterPage";

export const LINK = {
  getLinkBySlug: gql`
    ${MASTER_PAGE_FRAGMENT}
    query GetLinkBySlug($slug: String!) {
      getLinkBySlug(slug: $slug) {
        id
        masterPage {
          ...MasterPageFragment
        }
        title {
          ...LanguageFieldFragment
        }
        slug {
          ...LanguageFieldFragment
        }
        description {
          ...LanguageFieldFragment
        }
        metaTitle {
          ...LanguageFieldFragment
        }
        metaDescription {
          ...LanguageFieldFragment
        }
        metaKeywords {
          ...LanguageFieldFragment
        }
        pageTitle {
          ...LanguageFieldFragment
        }
        titleStatus
        status
        pageTitleStatus
        icon
        image
        type
        props {
          className
        }
        externalLink
        data {
          ... on Content {
            ...ContentFragment
          }
          ... on ComponentActiveItem {
            ...ComponentActiveItemFragment
          }
        }
      }
    }
  `,
};
