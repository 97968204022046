import { gql } from "@apollo/client";
import { LANGUAGE_FIELD_FRAGMENT } from "../Fragment/Language";
export const SETTING_FRAGMENT = gql`
  fragment SettingFragment on Setting {
    id
    metaTitle {
      ...LanguageFieldFragment
    }
    metaKeywords {
      ...LanguageFieldFragment
    }
    metaDescription {
      ...LanguageFieldFragment
    }
    logo
    scriptCode
  }
  ${LANGUAGE_FIELD_FRAGMENT}
`;
